import { brandPrimaryHover, mention } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

const size = {
	maxWidth: 300,
	minWidth: 300,
};

const highlighted = {
	background: `${mention}80`,
	cursor: 'pointer',
};

export const styleSheet = StyleSheet.create({
	container: {
		...size,
		minWidth: 100,
		position: 'relative',
	},
	header: {
		color: brandPrimaryHover,
		fontSize: '12px',
		letterSpacing: '1.3px',
		marginTop: 10,
		paddingLeft: 10,
		textTransform: 'uppercase',
	},
	highlighted: {
		...highlighted,
	},
	noResults: {
		color: '#ccc',
		fontSize: '14px',
		fontStyle: 'italic',
		paddingTop: 5,
		textAlign: 'center',
	},
	option: {
		':hover': {
			...highlighted,
		},
		padding: '5px 0 5px 30px',
	},
	resultsContainer: {
		...size,
		background: '#fff',
		border: '1px solid #ccc',
		borderRadius: 4,
		boxSizing: 'border-box',
		padding: '10px 10px 15px',
	},
});
